//box size reset
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'open-sans';
}
body {
  margin: 0;
  font-family: 'open-sans';
}

h1 {
  margin: 0;
}
