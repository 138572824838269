.cal-shift-card {
  display: flex;
  width: 8.375rem;
  cursor: pointer;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 0.75rem;
    height: 0.75rem;
    background-color: red;
    border-radius: 50%;
    transform: translate(30%, -70%);
    border: 2px solid white;
  }

  &.has-status-indicator::after {
    display: block;
  }

  .shift-card-header {
    padding: 0.375rem;
    padding-bottom: 0.125rem;
    padding-top: 0.3125rem;
  }

  .shift-card-body {
    display: flex;
    flex: auto;
    justify-content: space-evenly;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .shift-card-footer {
    padding: .625rem;
    margin-top: -0.9375rem;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 50ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
