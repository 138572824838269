@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.side-bar {
  display: flex;
  height: 16.5875rem;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: $white;
  background-color: $nurse-dash-indigo;
  justify-content: center;
  @include tablet {
    margin-bottom: 5rem;
  }

  @include laptop {
    display: flex;
    border-radius: 0px 32px 32px 0px;
    padding-top: 0;
    height: 100vh;
    width: 45%;
    margin-bottom: 0rem;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.5rem;
    width: 76.4%;

    @include laptop {
      margin: 0rem 5rem 0rem 5rem;
      max-width: 60%;
    }
  }

  &__title {
    font-size: 22px;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    @include tablet {
      font-size: 1.625rem;
    }

    @include laptop {
      font-size: 2.45rem;
      line-height: 2.5rem;
      font-weight: 800;
      margin-bottom: 2.5rem;
    }
  }

  &__text {
    line-height: 1.3rem;
    font-size: 0.8688rem;
    font-weight: 600;
    margin-top: -0.5rem;
    @include tablet {
      font-size: 1.1875rem;
    }
    @include laptop {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }
  }

  &__text--bold {
    @include laptop {
      font-weight: 800;
    }
  }

  &__text--show {
    display: none;

    @include laptop {
      display: unset;
      line-height: 1.75rem;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__line-break {
    @include laptop {
      padding-bottom: 2rem;
      display: table;
    }
  }
}
