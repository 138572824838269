@use '../../../shared/styles/partials/colors' as *;
@use '../../../shared/styles/partials/mixins' as *;

.form {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;

  @include laptop {
    align-items: center;
    border-radius: 6px;
    padding-top: 3.5rem;
    padding-bottom: 0.5rem;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include tablet {
      margin-bottom: 7rem;
    }
    @include laptop {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 85%;
      margin-bottom: 4rem;
    }
  }

  &__logo {
    height: 4.375rem;
    width: 4.375rem;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    @include laptop {
      font-weight: 400;
      font-size: 1.7rem;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__sub-title {
    text-align: center;
    margin-bottom: 1rem;

    @include laptop {
      font-weight: 400;
      font-size: 0.975rem;
      line-height: 1.25rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  &__auth-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__link {
    color: $cyan;
  }
  &__link:hover {
    cursor: pointer;
  }
}
