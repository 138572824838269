@use '../../../../shared/styles/partials/colors' as *;
@use '../../../../shared/styles/partials/mixins' as *;

.modal {
  padding: 1rem;
}
.label {
  font-weight: 700;
  color: #4a5568;
  margin-bottom: 0.5rem;
  size: 0.75rem;
}

.value {
  font-weight: 400;
  color: #1a202c;
  margin-bottom: 2rem;
  size: 0.875rem;
}
