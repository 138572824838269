.facility-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &__icon {
    height: 1rem;

    &__select {
      margin-right: 0.5rem;
    }
  }
}
