@use '../../../shared/styles/partials/mixins' as *;

.sign-up-btn {
  margin-bottom: 1rem;
  @include tablet {
    width: 40%;
  }
  @include laptop {
    height: 3rem;
    width: 100%;
  }
}
