.calendar-selectors {
  display: flex;
  flex: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 700px;

  .calendar-selector {
    display: flex;
    flex: auto;
    color: black;
    padding-right: 2px;
  }
}

.view-selectors {
  display: flex;
  flex: auto;
}
