@use '../../shared/styles/partials/mixins' as *;
@use '../../shared/styles/partials/colors' as *;

.error-title {
  font-weight: 800;
  font-size: 1.3875rem;
  text-align: center;
  @include tablet {
    font-size: 1.6875rem;
  }
  margin-bottom: 0.5rem;
}
