@use './shared/styles/partials/global' as *;
@use './shared/styles/partials/colors' as *;
@use './shared/styles/partials/mixins' as *;
@use './shared/styles/partials/typography' as *;

/*Box sizing reset based on this best practices article: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/*/
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
