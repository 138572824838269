@use '../../../../shared/styles/partials/colors' as *;
@use '../../../../shared/styles/partials/mixins' as *;

.create-profile {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 1rem;
  @include tablet {
    max-width: 65rem;
    margin-left: 1rem;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.875rem;
  }

  &__name-and-edit-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    @include tablet {
      // display: none;
    }
  }

  &__name-box {
    display: flex;
    gap: 0.5rem;
  }

  &__name {
    font-weight: 600;
  }

  &__icon-btn {
    @include tablet {
      display: none;
    }
  }

  &__edit-btn {
    background-color: white;
    border: 1px solid $nurse-dash-indigo;
    color: $nurse-dash-indigo;
    display: none;
    @include tablet {
      display: flex;
    }
  }
  &__description-box {
    display: none;
    @include tablet {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
    }
  }

  &__description-header {
    font-weight: 600;
    font-size: 1.5rem;
  }
  &__description-sub-header {
    font-weight: 400;
    font-size: 1rem;
  }

  &__form {
    background-color: white;
  }

  &__form-control {
    @include tablet {
      display: flex;
      gap: 1rem;
    }
  }
  &__form-split {
    @include tablet {
      width: 50%;
    }
  }

  &__form-error {
    color: red;
    margin-bottom: 1.5rem;
  }

  &__input {
    margin-bottom: 0.5rem;
  }

  &__input-addon {
    display: none;
    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  &__btn-box {
    display: flex;
    justify-content: center;
    gap: 1rem;
    @include tablet {
      justify-content: end;
    }
  }
}
