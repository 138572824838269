.message-card {
  position: relative;
  &::after {
    content: '';
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 0.75rem;
    height: 0.75rem;
    background-color: red;
    border-radius: 50%;
    // transform: translate(30%, -70%);
    border: 2px solid white;
  }

  &.has-unread-indicator::after {
    display: block;
  }

}
