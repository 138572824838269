@use '../../../../../shared/styles/partials/mixins' as *;
.permission-card {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  &__title {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &__sub-title {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  &__card-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
  }
  &__label-container {
    display: flex;
    justify-content: space-around;
    font-size: 0.75rem;
    font-weight: 700;
    border-bottom: 1px solid #e2e8f0;
    width: 95%;
    text-align: center;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  &__labels {
    @include tablet {
      width: 100%;
      text-align: left;
    }
  }
  &__permission-container {
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    justify-content: space-around;
    padding: 1.5rem 0rem;
    height: 9.625rem;
    width: 95%;
    margin: 0 auto;

    @include tablet {
      border-bottom: 1px solid #e2e8f0;
      width: 95%;
      text-align: center;
      height: 3.5rem;
    }
  }
  &__permissions {
    width: 5rem;
    @include tablet {
      width: 100%;
      text-align: left;
    }
  }
}

.bg {
  background-color: #f8f8f8;
  @include tablet {
    background-color: initial;
  }
}
