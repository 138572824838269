//Primary colors
$nurse-dash-indigo: #313871;
$nurse-dash-indigo-darker: #313971;
$white: #ffffff;
$black: #2d333a;
$cyan: #3799ff;
$grey: #e4e4e4;

//Secondary colors
$dark-blue: #2d3748;
$light-grey: #718096;
$lighter-grey: #e2e8f0;
