@use '../../shared/styles/partials/mixins' as *;
@use '../../shared/styles/partials/colors' as *;
.login {
  display: flex;
  flex-direction: column;
  background-color: $white;
  @include laptop {
    flex-direction: row;
    align-items: center;
    background-color: $grey;
  }

  &__form-section {
    padding: 1rem;
    @include laptop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 55%;
      padding: 0rem 4rem;
    }
  }
}
