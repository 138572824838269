//colors schemes
@mixin white-on-indigo {
  background-color: $nurse-dash-indigo;
  color: $white;
}

//media queries for breakpoints
@mixin mobile {
  //375px
  @media (min-width: 23.438rem) {
    @content;
  }
}

@mixin tablet {
  //768px
  @media (min-width: 48rem) {
    @content;
  }
}
@mixin laptop {
  //1024px
  @media (min-width: 64rem) {
    @content;
  }
}

@mixin desktop {
  //1200px
  @media (min-width: 75rem) {
    @content;
  }
}
